<!--  -->
<template>
  <div class="div">
    <div class="banner">
      <div class="word1">关于我们</div>
      <div class="bannersmall"></div>
      <div class="txt1">领先的智能化碳信息技术平台</div>
    </div>

    <div class="outer5">
      <div class="box2">
        <span class="word3">
          <span class="small"></span>公司介绍
          <span class="small smalls"></span>
        </span>
      </div>
      <div class="box3 BLANK">
        <span class="txt5">天朗易从科技（北京）有限公司</span>
      </div>
      <div class="box4 BLANK">
        <div class="outer6 flex-col"></div>
      </div>
      <div class="box5 justify-between">
        <div class="wrap1 flex-col"></div>
        <span class="infoBox1">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <i class="tl">天朗易从科技</i
          >是一家通过科技创新在高质量可持续发展、绿色低碳转型、应对社会责任及环境变化等方面持续为客户赋能的信息技术科技公司。
          <br class="BLANK" />
          <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们致力于通过物联网、大数据、人工智能及元宇宙等信息技术领域科技创新与产业的深度融合，为行业企业及政务客户提供符合“ESG“框架与“碳中和”目标下全场景、多维度的数字化产品及IT解决方案服务。
          <br class="BLANK" />
          <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;在最新ESG监管要求及“双碳”政策背景下，企业需要在提升效率的同时，更深入思考如何进行低碳、高质量发展，而数字化则是其中的关键要素。基于自身在新基建、企业服务及公共服务/教育等领域的实践积累,天朗易从科技持续优化能效,提高质量及服务满意度，在以往最佳实践及成功经验基础上不断创新，砥砺前行，研发创新突破性的信息技术效率工具产品及方案，与客户携手，推动行业生产力在新背景下的可持续发展。
          <br class="BLANK" />
          <br />
        </span>
      </div>
      <!-- <div class="box6 flex-col BLANK">
        <div class="bd2 justify-between">
          <div class="box7 flex-col">
            <img class="icon2" referrerpolicy="no-referrer" src="../../assets/AboutUs/icon.png" />
          </div>
          <span class="word4">公司简介</span>
        </div>
      </div> -->
      <div class="box8 flex-col BLANK">
        <img
          class="img1"
          referrerpolicy="no-referrer"
          src="../../assets/AboutUs/company.png"
        />
      </div>
    </div>
    <div class="outer6">
      <div class="word6">
        <span class="small"></span>资质荣誉
        <span class="small smalls"></span>
      </div>
      <!-- <div class="inside bannersmall" id="zizhi">
        <ul>
          <li class="li1">
            <img
              src="../../assets/AboutUs/4.jpg"
              alt
            />
          </li>
          <li class="li1">
            <img
              src="../../assets/AboutUs/d1f9d57a5cc219e0d5ef8e95891a1ad.jpg"
              alt
            />
          </li>
          <li class="li2">
            <img src="../../assets/AboutUs/704c6537d9c6abd0fc7fb65edc22078.jpg" alt />
          </li>
          <li class="li3">
            <img src="../../assets/AboutUs/pdf2.png" alt />
          </li>
          <li class="li2">
            <img src="../../assets/AboutUs/f721857609081dfd8cf76f8a43a1809.jpg" alt />
          </li>
          <li class="li1 ">
            <img
              src="../../assets/AboutUs/1111.jpg"
              alt
            />
          </li>
          <li class="li1 finally">
            <img src="../../assets/AboutUs/8b4f4ae5adb5258e1db9ff458b2d35d.jpg" alt />
          </li>
        </ul>
      </div> -->
      <!-- BLANK -->
      <div class="carousel">
        <el-carousel
          :interval="4000"
          type="card"
          :height="scale > 1024 ? '400px' : '250px'"
        >
          <el-carousel-item>
            <img src="../../assets/AboutUs/pdf2.png" alt />
          </el-carousel-item>
          <el-carousel-item>
            <img
              src="../../assets/AboutUs/704c6537d9c6abd0fc7fb65edc22078.jpg"
              alt
            />
          </el-carousel-item>
          <el-carousel-item>
            <img
              src="../../assets/AboutUs/d1f9d57a5cc219e0d5ef8e95891a1ad.jpg"
              alt
            />
          </el-carousel-item>
          <el-carousel-item>
            <img
              src="../../assets/AboutUs/f721857609081dfd8cf76f8a43a1809.jpg"
              alt
            />
          </el-carousel-item>
          <el-carousel-item>
            <img
              src="../../assets/AboutUs/8b4f4ae5adb5258e1db9ff458b2d35d.jpg"
              alt
            />
          </el-carousel-item>
          <el-carousel-item>
            <img src="../../assets/AboutUs/1111.jpg" alt />
          </el-carousel-item>
          <el-carousel-item>
            <img src="../../assets/AboutUs/4.jpg" alt />
          </el-carousel-item>
        </el-carousel>
      </div>

      <div></div>
    </div>

    <div class="outer7 bannersmall">
      <div class="word7">
        <span class="small"></span>联系我们
        <span class="small smalls"></span>
      </div>
      <div class="inside">
        <div class="left">
          <ul style="padding-left: 0">
            <!-- <li>
              <img src="../../assets/AboutUs/location.png" alt />
              <span>北京地址：{{ obj.location }}</span>
            </li>-->
            <!-- <li>
              <img src="../../assets/AboutUs/location.png" alt />
              <span>广州地址：{{ obj.location1 }}</span>
            </li>-->
            <li>
              <img src="../../assets/AboutUs//phone.png" alt />
              <span>联系电话：{{ obj.phone }}</span>
            </li>
            <li class="span_wx">
              <img src="../../assets/AboutUs/wx.png" alt />
              <span>微信公众号：{{ obj.Tencent }}</span>
              <img
                src="../../assets/4d77167f836ab6642938615837a766e.jpg"
                class="wximg "
                alt
              />
              <img
                src="../../assets/AboutUs/10d891744554f89a69fba6967b6fa96.jpg"
                class="wximg1 BLANK"
                alt
              />
            </li>
            <li>
              <img src="../../assets/AboutUs/mailbox.png" alt />
              <span>邮箱：{{ obj.mailbox }}</span>
            </li>
            <li class="BLANK">
              <img src="../../assets/AboutUs/wx.png" alt />
              <span>微信公众号：{{ obj.Tl }}</span>
            </li>
            <!-- <li class="img"></li> -->
          </ul>
        </div>
        <div class="address">
          <div>
            <img :src="img" style="width: 100%" alt />
            <span
              style="
                font-size: 18px;
                color: #666;
                margin-top: 10px;
                display: inline-block;
              "
              >地址：北京市东城区方家胡同46号E316</span
            >
          </div>
          <div>
            <img :src="img1" style="width: 100%" alt />
            <span
              style="
                font-size: 18px;
                color: #666;
                margin-top: 10px;
                display: inline-block;
              "
              >地址：广州市天河区天河路518号801房C01室</span
            >
          </div>
        </div>
        <!-- <div class="right">
          <img :src="img" alt />
        </div>-->
      </div>
    </div>
    <div class="outer8">
      <div class="word8">
        <span class="small"></span>加入我们
        <span class="small smalls"></span>
      </div>
      <!-- <div class="text8 BLANK">
        关于招聘的职位信息

      </div>-->
      <div class="t8">
        招聘邮箱：
        <a
          href="mailto:hr@skyezone.com.cn subject=test"
          style="color: red; cursor: pointer"
          >{{ obj.invite }}</a
        >
        请在邮件标题注明应聘职位
      </div>
      <div class="con">
        <ul>
          <li v-for="(item, index) in list" :key="index" @click="Onclick(item)">
            <div class="p1">{{ item.name }}</div>
            <!-- <div class="p2">{{ item.p2 }}</div> -->
            <div class="p3">
              <!-- {{ item.content }} -->
              <span v-html="item.content"></span>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <el-dialog title :visible.sync="dialogVisible" width="580px">
      <div style="height: 460px">
        <el-scrollbar style="height: 100%">
          <p
            style="
              text-align: center;
              font-size: 20px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #056bc6 !important;
              line-height: 28px;
              margin-bottom: 20px;
              overflow: hidden; //显示一行
              text-overflow: ellipsis;
              white-space: nowrap;
            "
          >
            {{ val.name }}
          </p>
          <!-- {{val.content}} -->
          <p v-html="val.content" style="line-height: 22px"></p>
        </el-scrollbar>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ditu from "../../assets/AboutUs/ditu.png";
import ditu1 from "../../assets/AboutUs/ditu1.png";
import { RecruitFindAll } from "../../api/api";
export default {
  data() {
    return {
      dialogVisible: false,
      val: {},
      obj: {
        location: "北京市东城区方家胡同46号E316",
        location1: "广州市天河区天河路518号801房C01室",
        wx: "wx",
        phone: "010 - 5848 3926",
        mailbox: "sales@skyezone.com.cn",
        Tencent: "零碳纪",
        Tl: "天朗易从科技",
        invite: "hr@skyezone.com.cn", //招聘邮箱
      },
      img: ditu,
      img1: ditu1,
      list: [
        {
          p1: "高级后端工程师",
          p2: "北京 / 20-35k / 3年 /本科",
          p3: "需要有责任心，会c#，.net，以及能够及时反应工作中的问题，学习能力需要有，有积极性，能够友好沟通…",
        },
        {
          p1: "高级后端工程师",
          p2: "北京 / 20-35k / 3年 /本科",
          p3: "1、熟悉Java， 熟练使用常用的Java技术框架，并对常 用的应用框架如Spring、SpringBoot、 MyBatis等有 深入的应用和优化经验。熟悉MySQL数据库，具备- -定的性能调优经验(如:优化SQL的查询速度、数据库配置参数调优 等) ;了解L inux的基本操作，熟悉常用Shell命令的使用;需求分析、系统设计工作，完成系统需求分析、设计说明书编写。熟悉设计模式和UML，熟悉面向对象的系统分析、设计与编程思想。",
        },
        {
          p1: "高级后端工程师",
          p2: "北京 / 20-35k / 3年 /本科",
          p3: "需要有责任心，会c#，.net，以及能够及时反应工作中的问题，学习能力需要有，有积极性，能够友好沟通,需要有责任心，会c#，.net，以及能够及时反应工作中的问题，学习能力需要有，有积极性，能够友好沟通…",
        },
      ],
      scale: 1024,
    };
  },
  mounted() {
    _hmt.push(["_trackEvent", "关于我们", "进入"]);
    RecruitFindAll({}).then((res) => {
      this.list = res.data.list;
      var zizhi = document.getElementById("zizhi");
      zizhi.scrollLeft = (zizhi.scrollWidth - zizhi.offsetWidth) / 2;
      // document.documentElement.scrollTop = (document.documentElement.scrollHeight - document.documentElement.offsetHeight) / 2;
    });
    this.scale = document.documentElement.clientWidth;
  },
  components: {},

  methods: {
    Onclick(val) {
      if (document.body.clientWidth < 1023) {
        return;
      }
      this.val = val;
      this.dialogVisible = true;
      // this.$alert(val.content, val.name, {
      //   dangerouslyUseHTMLString: true,
      // });
    },
  },
};
</script>
<style lang='less' scoped>
@import "./index.less";
.el-carousel {
  position: relative;
  width: 1200px;
  // height: 500px;
  margin: 0 auto;
  margin-top: 70px;
}
.el-carousel__item {
  text-align: center;
  img {
    width: 300px;
    height: 394px;
  }
}

/deep/.el-dialog__body {
  padding: 45px !important;
  padding-top: 0px !important;
}
@media screen and (max-width: 1023px) {
  /deep/.el-dialog__body {
    width: 50% !important;
  }
  /deep/.el-dialog__wrapper {
    width: 50% !important;
  }
  // .el-carousel {
  //   position: relative;
  //   width: 1980px;
  //   height: 500px;
  //   margin: 0 auto;
  //    margin-top: 70*5px;
  // }
  // .el-carousel__item {
  //   text-align: center;
  //   img {
  //     width: 500px;
  //     height: 394px;
  //   }
  // }
}
</style>
